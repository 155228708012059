import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import "./TeamStaticCard.scss"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")

const TeamStaticCard = ({ cardData,uniqueKey, isPreviewEnabled}) => {
  return (
    <div className={`team-static-card`}>
      <ScrollAnimation
        animateIn="animate__slideInUp"
        animateOnce
        delay={100}
        offset={100}
        
      >
        <Container>
          <div className="card-section_team">
            {cardData.map((data,index) => {
              return (
                <div
                key={data?.title}
                  className={`${
                    data.title.includes("Communi")
                      ? "card_team-list community"
                      : data.title === "Find your consultant"
                      ? "card_team-list customer"
                      : data.title === "Speak to a customer service specialist"
                      ? "card_team-list contact"
                      : "card_team-list"
                  }`}
                >
                  <div className="content-container">
                    <h3>{data.title}</h3>
                    {(data?.content?.data?.content || (data?.content&&isPreviewEnabled))&&
                    <div className="description">
                      <ContentModule Content={data?.content?.data?.content || data?.content} />
                    </div>
                    }
                    {/* <Link to={`${data}`}>
                    <button className="button button-filled-green">
                      {data?.cta?.title}
                    </button>
                  </Link> */}
                    <PreviewCTALink
                      class="button button-filled-green"
                      link={
                        data?.cta?.link
                          ? data?.cta?.link
                          : { external_link: data?.cta?.custom_link }
                      }
                      title={data?.cta?.title}
                      target_window={data?.cta?.target_window}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </Container>
      </ScrollAnimation>
    </div>
  )
}

export default TeamStaticCard
