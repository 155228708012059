import React from "react"
import { Container } from "react-bootstrap"
import "./TeamLandingBanner.scss"
import ScrollAnimation from "react-animate-on-scroll"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const TeamLandingBanner = ({ pageData, moduleData,uniqueKey, isPreviewEnabled }) => {
  return (
    <div className="team-landing-wrapper" key={uniqueKey}>
      <ScrollAnimation 
      animateIn="animate__slideInUp" animateOnce delay={100} offset={100}
      >
      <Container>
        <div className="team-landing-content">
          <h1>{moduleData?.title}</h1>
          {(moduleData?.content?.data?.content || (moduleData?.content && isPreviewEnabled))&&
          <div className="description">
            <ContentModule Content={moduleData?.content?.data?.content || moduleData?.content} />
          </div>
          }
        </div>
      </Container>
      </ScrollAnimation>
    </div>
  )
}

export default TeamLandingBanner
