import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { formTracking } from "../components/Common/utils"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import TeamLandingBanner from "../components/TeamLandingBanner/TeamLandingBanner"
import TeamListing from "../components/TeamListing/TeamListing"
import TeamStaticCard from "../components/TeamStaticCard/TeamStaticCard"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logoBlack from "../images/logo-white.svg"
import { removeDataAttributes } from "../components/Common/utils"
import qs from "qs"


const TeamLanding = ({ data, pageContext }) => {
  // const teamPageData = data.strapiPage
  const [teamPageData, setPageData] = useState(data?.strapiPage)
  const [loading,setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false)
  const [shareIcons, setShareIcons] = useState(false)

  const sortTeam = data.allStrapiTeam?.edges.sort(
    (a, b) => parseInt(a.node.sort) - parseInt(b.node.sort)
  )
  const teamMember = sortTeam
  const teamCategories = data.allStrapiTeamCategorie?.edges

  const searchValue =
    typeof window !== "undefined" ? window.history?.state?.inputValue : ""

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  let popUpData=""

  if(teamPageData?.add_page_modules?.length>0){
     const results=teamPageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        choose_menu: "*",
        select_popular_search:"*",
        seo:"*",
        add_page_modules: {
          on: {
            "page-modules.plain-content":{
              populate:"*"
            },
            "page-modules.static-card-items":{
              populate:{
                cards:{
                  populate:{
                    cta:{
                      populate:{
                        link: {
                          populate:{
                            parent:"*"
                          }
                        },
                        image:"*"
                      }
                    }
                  }
             }

          }
        }
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

    // const apiUrl=process.env.GATSBY_STRAPI_SRC+'/api/events/'+urlParams.get('strapi_id')+'?populate[0]=modules&populate[1]=banner.cta_1_link&populate[2]=banner.cta_2_link&populate[3]=modules.icon_content&populate[4]=modules.icon_content.icon_module.image&populate[5]=form_content&populate[6]=ggfx_results&populate[12]=banner.image'
    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
    setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)

        let myPrevData;
        if(teamPageData){
          myPrevData = teamPageData
        } else {
          myPrevData = {};
          myPrevData.add_page_modules=[]
          myPrevData.id = urlParams.get('strapi_id')
        }

        if(previewData){
          
          myPrevData.imagetransforms = previewData?.imagetransforms
          myPrevData.title = previewData?.title
          myPrevData.layout = previewData?.layout
          myPrevData.select_popular_search = previewData.select_popular_search
          myPrevData.choose_menu = previewData?.choose_menu

          myPrevData.ggfx_results = previewData?.ggfx_results
          myPrevData.add_page_modules=previewData?.add_page_modules
          setPageData(teamPageData => ({ ...teamPageData, myPrevData }));
          sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
      sessionStorage.removeItem("previewMeta")
    }

  },[])

  let urlParamsVal = ""
  let isPreviewVal = false

  if(typeof window !== "undefined"){
     urlParamsVal = new URLSearchParams(window.location.search);
    isPreviewVal = urlParamsVal.get('debug') === 'preview';
  }

  if(isPreviewVal){
    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }
  }

  if(enablePreview){
    return null
  }


  return (
    <Layout
      popUpData={popUpData}
      dark={true}
      popularSearch={teamPageData?.select_popular_search?.title}
    >
      <div className="layout-padding-top"></div>
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            choose_menu={teamPageData?.choose_menu}
            pagename={teamPageData?.title}
            type="share-button"
          />
          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>
        </div>
      </Container>
      {teamPageData?.add_page_modules?.map((item, i) => {
        return (
          <div key={i}>
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT" ||
              item?.__component === "page-modules.plain-content")
              && (
              <TeamLandingBanner isPreviewEnabled={isPreviewEnabled} uniqueKey={i} pageData={teamPageData} moduleData={item} />
            )}
          </div>
        )
      })}

      <TeamListing
        searchValue={searchValue}
        teamsData={teamMember}
        strapi_id={teamPageData?.strapi_id}
        teamCategories={teamCategories}
        isPreviewEnabled={isPreviewEnabled}
      />
      {teamPageData?.add_page_modules?.map((item, i) => {
        return (
          <div key={i}>
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" ||
              item?.__component === "page-modules.static-card-items")
              && (
              <TeamStaticCard isPreviewEnabled={isPreviewEnabled} uniqueKey={i} cardData={item?.cards} />
            )}
          </div>
        )
      })} 
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage


  //preview seo dynamic updates

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const metaTitle = PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)


  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
          const sessionMetaData = sessionStorage.getItem("previewMeta");
          if (sessionMetaData) {
            const parsedData = JSON.parse(sessionMetaData);
          if(parsedData?.metaTitle){
            setPreMetaTitle(parsedData?.metaTitle)
          }
        }
      } catch (error) {
        console.error("Error parsing session storage data:", error);
      }
    }
  }, 4000);

  // Clear the timeout if the component unmounts
  return () => clearTimeout(timer);
}, [isPreview]);

useEffect(() => {
  if(isPreview){
  document.title =  `${preMetaTitle} haus & haus`;
  }
}, [preMetaTitle]);
//


  return (
      <SEO title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle} 
      description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i/file_7035ecdafe.jpg?updated_at=2024-07-08T11:28:34.283Z"
      />
  )
}

export default TeamLanding

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          __typename
          id
          cards {
            title
            content {
              data {
                content
              }
            }
            cta {
              title
              link {
                slug
                strapi_parent {
                  slug
                }
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          __typename
          id
          content {
            data {
              content
            }
          }
          title
        }
      }
    }

    allStrapiTeamCategorie {
      edges {
        node {
          designation
          slug
        }
      }
    }

    allStrapiTeam(filter: { publish: { eq: true } }) {
      edges {
        node {
          image {
            url
          }
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
          imagetransforms {
            image_Transforms
          }
          Languages_Spoken {
            strapi_json_value
          }
          slug
          strapi_id
          designation
          name
          sort
          team_member {
            slug
            designation
          }
          choose_areas {
            title
            slug
          }
        }
      }
    }
  }
`
